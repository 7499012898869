window.addEventListener('load', function() {

  var lead_element = document.getElementsByClassName("leading_inquiry_footer")[0];
  // top_element, top_detail_elementは追従「トップへ」ボタンの位置調整に用いる。要素が存在しない場合は空要素を作成。
  var top_element = document.getElementsByClassName("gotop")[0] || document.createElement("div");
  var top_detail_element = document.getElementsByClassName("go_top_detail")[0] || document.createElement("div");
  var lead_element_height = lead_element.offsetHeight;
  var side_area_element = document.getElementById("js_side_area");
  var form_box_element = document.getElementById("PhSlideBox");
  var form_btn_element = document.getElementsByClassName("multitap-nextbtn_area")[0];
  if (form_box_element != null) var form_box_height = form_box_element.getBoundingClientRect().y;

  // lead elementの初期化
  lead_element.style.display = "none";
  lead_element.style.height = "0";

  // lead_element要素を徐々にスライドさせて非表示にする
  function leadSlideUp() {
    if (lead_element.offsetHeight > 0) {
      lead_element.style.height = (lead_element.offsetHeight - 30) + "px";
    } else {
      lead_element.style.height = 0;
      lead_element.style.display = "none";
    }
  }

  // lead_element要素を徐々にスライドさせて表示する
  function leadSlideDown() {
    lead_element.style.display = "block";
    if (lead_element.offsetHeight < lead_element_height) {
      lead_element.style.height = (lead_element.offsetHeight + 15) + "px";
    } else {
      lead_element.style.height = lead_element_height + "px";
      lead_element.style.padding = "0.8em 0";
      top_element.style.height = top_element.offsetHeight + lead_element_height + 5 + "px";
    }
  }

  function leadForm(scrollTop) {
    if (navigator.userAgent.match(/(iPhone|iPad|iPod|Android)/)) {
      if ((scrollTop + window.innerHeight > form_box_height) &&
          (form_box_height + form_box_element.offsetHeight - form_btn_element.offsetHeight > scrollTop)) {
        lead_element.style.padding = "0";
        lead_element.style.display = "none";
        leadSlideUp();
      } else {
        leadSlideDown();
      }
    }
  }

  window.addEventListener("scroll", function() {
    var scrollTop = document.body.scrollTop || document.documentElement.scrollTop
    if (scrollTop > 100) {
      if (form_box_element != null) {
        leadForm(scrollTop);
      } else {
        leadSlideDown();
      }
    } else {
      lead_element.style.padding = "0";
      leadSlideUp();
    }

    if (scrollTop == 0){
      lead_element.style.height = 0;
      lead_element.style.display = "none";
    }

    // フッター固定する
    // ドキュメントの高さ
    lead_scroll_height = this.document.documentElement.scrollHeight;
    // ウィンドウの高さ+スクロールした高さ→　現在のトップからの位置
    lead_scroll_position = document.documentElement.clientHeight + scrollTop;
    // フッターの高さ
    lead_foot_height = document.getElementById("footer").clientHeight;

    // 閲覧履歴とフッターナビの余白調整
    if (side_area_element != null) side_area_element.style.marginBottom = "6em";
    top_element.classList.add("leading_inquiry_fit");
    top_detail_element.classList.add("leading_inquiry_top");

    if ( lead_scroll_height - lead_scroll_position <= lead_foot_height ) {
      if (navigator.userAgent.match(/(iPhone|iPad|iPod|Android)/)) {
        // 現在の下から位置が、フッターの高さの位置にはいったら
        //  ".leading_inquiry_footer"のpositionをabsoluteに変更し、フッターの高さの位置にする
        lead_element.style.position = "absolute";
      } else {
        // pcの場合は非表示にする
        lead_element.style.display = "none";
      }
      lead_element.classList.remove("leading_inquiry_footer_background");
    } else {
      lead_element.style.position = "fixed";
      lead_element.style.bottom = "0px";
      lead_element.classList.add("leading_inquiry_footer_background");
    }
  });
});
